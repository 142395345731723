exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-competition-terms-and-conditions-js": () => import("./../../../src/pages/competition-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-competition-terms-and-conditions-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offset-agria-pet-insurance-index-js": () => import("./../../../src/pages/offset/agria-pet-insurance/index.js" /* webpackChunkName: "component---src-pages-offset-agria-pet-insurance-index-js" */),
  "component---src-pages-offset-business-checkout-js": () => import("./../../../src/pages/offset/business/checkout.js" /* webpackChunkName: "component---src-pages-offset-business-checkout-js" */),
  "component---src-pages-offset-buy-a-gift-js": () => import("./../../../src/pages/offset/buy-a-gift.js" /* webpackChunkName: "component---src-pages-offset-buy-a-gift-js" */),
  "component---src-pages-offset-checkout-js": () => import("./../../../src/pages/offset/checkout.js" /* webpackChunkName: "component---src-pages-offset-checkout-js" */),
  "component---src-pages-offset-green-salon-collective-index-js": () => import("./../../../src/pages/offset/green-salon-collective/index.js" /* webpackChunkName: "component---src-pages-offset-green-salon-collective-index-js" */),
  "component---src-pages-offset-index-js": () => import("./../../../src/pages/offset/index.js" /* webpackChunkName: "component---src-pages-offset-index-js" */),
  "component---src-pages-offset-offset-your-pet-index-js": () => import("./../../../src/pages/offset/offset-your-pet/index.js" /* webpackChunkName: "component---src-pages-offset-offset-your-pet-index-js" */),
  "component---src-pages-offset-offset-your-pet-offset-your-cat-js": () => import("./../../../src/pages/offset/offset-your-pet/offset-your-cat.js" /* webpackChunkName: "component---src-pages-offset-offset-your-pet-offset-your-cat-js" */),
  "component---src-pages-offset-offset-your-pet-offset-your-dog-js": () => import("./../../../src/pages/offset/offset-your-pet/offset-your-dog.js" /* webpackChunkName: "component---src-pages-offset-offset-your-pet-offset-your-dog-js" */),
  "component---src-pages-offset-offset-your-pet-offset-your-horse-js": () => import("./../../../src/pages/offset/offset-your-pet/offset-your-horse.js" /* webpackChunkName: "component---src-pages-offset-offset-your-pet-offset-your-horse-js" */),
  "component---src-pages-offset-thank-you-js": () => import("./../../../src/pages/offset/thank-you.js" /* webpackChunkName: "component---src-pages-offset-thank-you-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-shop-basket-js": () => import("./../../../src/pages/shop/basket.js" /* webpackChunkName: "component---src-pages-shop-basket-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

